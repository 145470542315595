
















































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import { EmailsInput } from "@/components/common";
@Component({ components: { EmailsInput } })
export default class AddGroupCard extends Vue {
  @Getter("groups/addGroupLoading") addGroupLoading!: boolean;
  @Action("groups/addGroup") addGroupAction!: Function;

  addingGroup = false;
  newGroupName = "";
  nextUser = "";
  newUsers: string[] = [];

  // Add group
  toggleAddGroup(): void {
    this.addingGroup = !this.addingGroup;
  }
  groupNameRules = [
    (n: string) => {
      if (n.length < 3) return "Enter group name with length >= 3";
      else return true;
    },
  ];
  chipRules = [
    (chips: string[]) => {
      if (chips.some(c => c.length < 3)) return true;
      let pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (chips.some(c => !pattern.test(c))) return "Invalid email";
      else return true;
    },
  ];
  submitAddGroup() {
    if (this.newGroupName.length < 3) return;
    if (this.nextUser) this.newUsers.push(this.nextUser);
    let data = {
      groupName: this.newGroupName,
      userEmails: this.newUsers,
    };
    this.addGroupAction(data);
    this.newGroupName = "";
    this.newUsers = null as any;
    this.addingGroup = false;
  }
}
