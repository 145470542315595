






































































































import Vue from "vue";
import { GroupUser } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class GroupUsersList extends Vue {
  @Prop({ default: () => -1 }) groupId!: number;
  @Prop({ default: () => [] as GroupUser[] }) filteredUsers!: GroupUser[];
  @Prop({ default: () => false }) isManager!: boolean;

  // Getters
  @Getter("profile/getUserEmail") currentUserEmail!: string;
  @Getter("groups/removeUserLoading") removeUserLoading!: boolean;
  @Getter("groups/removedUserEmail") removedUserEmail!: boolean;
  @Getter("groups/editedUserEmail") editedUserEmail!: boolean;
  @Getter("groups/changeUserRoleLoading") changeUserRoleLoading!: boolean;

  // Actions
  @Action("groups/changeUserRole") changeUserRoleAction!: Function;
  @Action("groups/removeUser") removeUserAction!: Function;

  removeUserDialog = false;
  userToRemove = {} as GroupUser;

  get otherUsers() {
    return this.filteredUsers.filter(u => !u.IsOwner);
  }
  get owner() {
    return this.filteredUsers.find(u => u.IsOwner == true)!;
  }

  // change user role
  changeUserRole(user: GroupUser) {
    if (this.changeUserRoleLoading) return;
    let data = {
      groupId: this.groupId,
      userEmail: user.Email,
      isManager: !user.IsManager,
      isCurrentUser: this.currentUserEmail == user.Email,
    };
    this.changeUserRoleAction(data);
  }

  // Remove user
  get confirmRemoveMessage() {
    const email = this.userToRemove.Email;
    return this.$t("groups.confrem", {
      u: email == this.currentUserEmail ? this.$t("groups.remself") : email,
    });
  }
  toggleRemoveUser(user: GroupUser = {} as GroupUser) {
    this.removeUserDialog = !this.removeUserDialog;
    this.userToRemove = user;
  }
  removeUser() {
    if (this.removeUserLoading) return;
    let data = {
      groupId: this.groupId,
      userEmail: this.userToRemove.Email,
      isCurrentUser: this.currentUserEmail == this.userToRemove.Email,
    };
    this.removeUserAction(data);
    this.toggleRemoveUser();
  }
}
